import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { MdEmail } from "react-icons/md"
import { TextField, Button, makeStyles } from "@material-ui/core"
import BackContact from "../../images/svg/back-contactpage.svg"
import BackHome from "../../images/svg/back-contact.svg"
import { useForm } from "react-hook-form"
import Loading from "../common/Loading"
import Whatsapp from "../../images/svg/icon-whatsapp.svg"
import { navigate } from 'gatsby';

const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const useStyles = makeStyles(() => ({
  root: {
    "& .Mui-error": {
      color: "#fff",
    },
  },
}))

const StyledTextField = styled(TextField)`
  ${props =>
    props.location.pathname === "/contacto/" &&
    css`
      input {
        color: #000 !important;
      }
      div {
        color: #000 !important;
        background: rgba(0, 0, 0, 0.15) !important;
        &:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
      label,
      textarea {
        color: #000 !important;
      }
    `}
`

const Contact = ({ location }) => {
  const [messageLength, setMessageLength] = useState()
  const [submitStatus, setSubmitStatus] = useState("standby")
  const [submitClick, setSubmitClick] = useState(false)
  const [sendData, setSendData] = useState()
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })
  const onSubmit = data => {
    setSendData(data)
    setSubmitClick(true)
  }

  const url = `${URL}/contacto/`

  const fetchUrl = async () => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sendData),
    })
    const json = await response.json()
    if (json.error) {
      setSubmitStatus("error")
    } else {
      setSubmitStatus("send")
      navigate('/agradecimiento');
      reset(response)
    }
  }
  useEffect(() => {
    if (submitClick) {
      setSubmitStatus("working")
      fetchUrl()
      setSubmitClick(false)
    }
  }, [submitClick])

  const remainCharacter = value => {
    const maxChar = 600
    if (value === maxChar) {
      return "Limite de catacteres alcanzado"
    } else if (value >= maxChar - 100) {
      return maxChar - value
    }
  }
  const classes = useStyles()
  return (
    <Section bg={BackContact} location={location}>
      {location.pathname === "/contacto/" ? (
        <BackContact className="backImage" />
      ) : (
        <BackHome className="backImage" />
      )}
      <div className="ContactForm">
        {location.pathname !== "/contacto/" ? (
          <>
            <div className="RoundIcon">
              <MdEmail />
            </div>
            <h2 className="title">Hazlo inteligente. Hazlo con nosotros</h2>
            <p>Conoce más de nuestras soluciones.</p>
          </>
        ) : (
          <>
            <h2 className="title" style={{ marginBottom: "1rem" }}>
              Contacto
            </h2>
              <h2 className="title">Hazlo inteligente. Hazlo con nosotros</h2>
              <p>Conoce más de nuestras soluciones.</p>
          </>
        )}
	{/* <div className="linkAccess">
 	  Contáctanos al &nbsp;&nbsp;<Whatsapp />&nbsp;&nbsp; +5491133101591 <br />
  	  <a href="https://api.whatsapp.com/send?phone=5491133101591" className="link-white">
    	   <b>Haz click aquí</b>
	  </a>
	</div> */}

        <form
          className="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="formControl">
            <StyledTextField
              label="Nombre / Empresa"
              className={location.pathname !== "/contacto/" ? classes.root : ""}
              inputRef={register({ required: true })}
              name="name"
              id="name"
              inputProps={{ maxLength: 40 }}
              placeholder="Escribe aquí"
              size="small"
              variant="filled"
              fullWidth={true}
              location={location}
              helperText={errors.name ? "Campo obligatorio" : false}
              error={errors.name ? true : false}
            />
          </div>
          <div className="formControl">
            <StyledTextField
              label="Correo electrónico"
              className={location.pathname !== "/contacto/" ? classes.root : ""}
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                },
              })}
              name="mail"
              id="mail"
              placeholder="Escribe aquí"
              size="small"
              inputProps={{ maxLength: 50 }}
              variant="filled"
              fullWidth={true}
              type="email"
              location={location}
              helperText={errors.mail && "Ingrese un formato de correo válido"}
              error={errors.mail ? true : false}
            />
          </div>
          <div className="formControl">
            <StyledTextField
              label="Teléfono"
              className={location.pathname !== "/contacto/" ? classes.root : ""}
              inputRef={register}
              name="phone"
              id="phone"
              inputProps={{ maxLength: 20 }}
              type="number"
              placeholder="Escribe aquí"
              size="small"
              variant="filled"
              fullWidth={true}
              location={location}
            />
          </div>
          <div className="formControl">
            <StyledTextField
              label="Mensaje"
              className={location.pathname !== "/contacto/" ? classes.root : ""}
              inputRef={register({ required: true, maxLength: 600 })}
              name="message"
              id="message"
              placeholder="Escribe aquí"
              size="small"
              variant="filled"
              inputProps={{ maxLength: 600 }}
              fullWidth={true}
              multiline
              location={location}
	      rows="3"
              onChange={e => setMessageLength(e.target.value.length)}
              helperText={
                errors.message &&
                errors.message.type === "required" &&
                "Campo obligatorio"
              }
              error={errors.message ? true : false}
            />
            {messageLength > 100 && (
              <p className="falseError">{remainCharacter(messageLength)}</p>
            )}
          </div>
          <Button
            type="submit"
            variant={ location.pathname === "/contacto/" ? "contained" : "outlined" }
            color={ location.pathname === "/contacto/" ? "secondary" : "inherit" }
            // disabled={formState.isValid ? false : true}
            fullWidth={true}
          >
            {submitStatus === "working" ? (
              <Loading
                style={{
                  height: "22px",
                  display: "flex",
                  alignItems: "center",
                }}
                color={
                  location.pathname !== "/contacto/"
                    ? "white"
                    : "rgba(0,0,0,.2)"
                }
              />
            ) : (
              "Enviar"
            )}
          </Button>
       </form>
      </div>
    </Section>
  )
}
export default Contact

const Section = styled.section`
  text-align: center;
  padding: 2rem 0;
  position: relative;
  overflow-x: hidden;
  @media screen and (min-width: 600px) {
    padding: 3rem 0;
  }
  .backImage {
    position: absolute;
    right: -150px;
    top: -120px;
    max-width: 35%;
    height: auto;
    transform: rotate(90deg);
    @media screen and (min-width: 600px) {
      right: -220px;
      top: -160px;
      transform: initial;
    }
  }
  .MuiFormControl-root {
    color: red;
  }
  ${props =>
    props.location.pathname !== "/contacto/" &&
    css`
      background-color: #007881;
      color: #fff;
      .backImage {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 35%;
        height: auto;
        transform: rotate(90deg);
        @media screen and (min-width: 600px) {
          left: 0;
          bottom: 0;
          top: initial;
          max-width: 45%;
          transform: initial;
        }
      }
    `}
  .ContactForm {
    max-width: 572px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
  }
  .RoundIcon {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 1.25rem;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.75rem;
    @media screen and (min-width: 600px) {
      margin-bottom: 1.25rem;
      width: 72px;
      height: 72px;
      font-size: 2rem;
    }
  }
  .form {
    width: 74%;
    margin: 0;
    @media screen and (max-width: 600px) {
      width: 90%;
    }
  }
  .formControl {
    margin-bottom: 1.5rem;
    .falseError {
      margin-left: 14px;
      margin-right: 14px;
      margin-top: 4px;
      font-size: 0.75rem;
      text-align: left;
      font-family: Raleway, sans-serif;
      font-weight: 400;
      line-height: 1.66;
      color: #f44336;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .formControl:nth-last-of-type(1) {
    margin-bottom: 1.75rem;
  }
  .link-white {
    color: #ffffff;
  }
  .linkAccess {
    padding: 10px 20px;
    background: #23CD64;
    width: 25rem;
    height: 4rem;
    border-radius: 2rem;
    color: white;
    margin-bottom: 2.5rem;
    line-height: 1.5rem;
    @media screen and (max-width: 600px) {​​​​​​​
      width: 90%;
   }  ​​  

  }
`
